import { all, call } from 'redux-saga/effects';
import { values } from 'lodash';
import coreSagas from 'core/sagas';
import searchFormSaga from 'core/modules/SearchForm/sagas';
import merchantOfferModalSaga from 'core/modules/Modal/components/MerchantOfferModal/sagas';
import emailOptInSubscribeModalSaga from 'core/modules/Modal/components/EmailOptInSubscribeModal/sagas';
import emailOptInSuccessModalSaga from 'core/modules/Modal/components/EmailOptInSuccessModal/sagas';
import editEmailPreferencesModalSaga from 'core/modules/Modal/components/EditEmailPreferencesModal/sagas';
import favoritesAlertModalSaga from 'core/modules/Modal/components/FavoritesAlertModal/sagas';
import searchModalSaga from 'core/modules/Modal/components/SearchModal/sagas';
import hiwVideoModal from 'core/modules/Modal/components/HiwVideoModal/sagas';
import favoritesSaga from './favorites';

const combinedSagas = {
  ...coreSagas,
  searchFormSaga,
  merchantOfferModalSaga,
  emailOptInSubscribeModalSaga,
  emailOptInSuccessModalSaga,
  favoritesSaga,
  editEmailPreferencesModalSaga,
  favoritesAlertModalSaga,
  searchModalSaga,
  hiwVideoModal,
};

export default function* rootSaga() {
  yield all(values(combinedSagas).map(saga => call(saga)));
}
