import { merge } from 'lodash';
import { combineReducers } from 'redux';
import coreReducers from 'core/reducers';

// Top level org config
import config from 'org/configs/org.config';

// Org reducers
import footerBanner from '../modules/FooterBanner/reducers';

export default combineReducers(merge(coreReducers, {
  orgConfig: () => config,
  footerBanner,
}));
