import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { fetchPlacements } from 'core/actions/ocapi';
import ComponentErrorBoundary from 'core/modules/ErrorBoundaries/ComponentErrorBoundary';

import {
  selectFooterBannerConfig,
  selectFooterBannerIsLoading,
  selectFooterBannerIsLoaded,
  selectFooterBannerPlacement,
} from './selectors';

import './FooterBanner.scss';

function FooterBanner() {
  const config = useSelector(selectFooterBannerConfig);
  const isLoading = useSelector(selectFooterBannerIsLoading);
  const isLoaded = useSelector(selectFooterBannerIsLoaded);
  const placement = useSelector(selectFooterBannerPlacement);
  const { api: { params, options } } = config;
  const { clickUrl, assets } = placement;

  const dispatch = useDispatch();

  useEffect(() => {
    if (!isLoaded && !isLoading) {
      dispatch(fetchPlacements(params, options));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isEmpty(placement)) {
    return null;
  }

  return (
    <div className="mn_footerBanner">
      <a href={clickUrl}><img src={assets.image} alt={assets.imageAlternateText} /></a>
    </div>
  );
}

export default (props) => <ComponentErrorBoundary><FooterBanner {...props} /></ComponentErrorBoundary>;
