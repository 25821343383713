import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Loadable from 'react-loadable';

// A syntax sugar for a Loadable plugin call
// Note: the module path system.import can't be fully set as an expression
// so the ./pages folder is hardcoded here to define the correct context for webpack
const LoadableWrapper = pageComponentPath => Loadable({
  loader: () => import(`./pages${pageComponentPath}`),
  loading: () => (null),
});

// Note: pageComponentPath should be related to the ./pages folder
const routesConfig = [
  { name: 'home', route: '(/|/h____.htm)', pageComponentPath: LoadableWrapper('/Home') },
];

function Routes() {
  return (
    <Switch>
      {routesConfig.map(({ name, route, pageComponentPath }) =>
        <Route key={name} exact path={route} component={pageComponentPath} />)}
      <Route component={LoadableWrapper('/Error')} />
    </Switch>
  );
}

export default Routes;
